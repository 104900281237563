import { RouteRecordRaw } from "vue-router";
import companyContactRoutes from "./companyContactRoutes";

const companyRoutes: RouteRecordRaw[] = [
  {
    path: "companies",
    name: "companies",
    component: () =>
      import(
        /* webpackChunkName: "companies layout" */ "../modules/admin/submodules/company/layout/CompanyLayout.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: 'redirect',
        redirect: { name: "company-list" }, // Redirige a `company-list` cuando se accede a `companies` sin una ruta específica
      },
      {
        path: "list",
        name: "company-list",
        component: () =>
          import(
            /* webpackChunkName: "company list" */ "../modules/admin/submodules/company/views/CompanyList.vue"
          ),
        meta: {
          requiresAuth: true,
          breaddcums: [
            {
              name: "Companies",
              redirect: "company-list",
              disabled: false,
            }
          ],
          parent: "company-list",
        },
      },
      {
        path: "edit/:companyId",
        name: "edit-company",
        component: () =>
          import(
            /* webpackChunkName: "edit company" */ "../modules/admin/submodules/company/views/EditCompany.vue"
          ),
        meta: {
          requiresAuth: true,
          breaddcums: [
            {
              name: "Companies",
              redirect: "company-list",
              disabled: false,
            },
            {
              name: "Edit",
              redirect: "edit-company",
              disabled: true,
              paramsKey: "companyId",
            },
          ],
          parent: "company-list",
        },
      },
      {
        path: "create",
        name: "create-company",
        component: () =>
          import(
            /* webpackChunkName: "create company" */ "../modules/admin/submodules/company/views/CreateCompany.vue"
          ),
        meta: {
          requiresAuth: true,
          breaddcums: [
            {
              name: "Companies",
              redirect: "company-list",
              disabled: false,
            },
            {
              name: "Create",
              redirect: "create-company",
              disabled: true,
            },
          ],
          parent: "company-list",
        },
      },
      ...companyContactRoutes
    ],
  },
];

export default companyRoutes;
