import { RouteRecordRaw } from "vue-router";
import companyRoutes from "./companyRoutes";
import userRoutes from "./userRoutes";
import propertyRoutes from "./propertyRoutes";

const routes: RouteRecordRaw[] = [
  {
    path: "",
    name: "auth",
    component: () =>
      import(
        /* webpackChunkName: "auth layout" */ "../modules/auth/layout/AuthLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "sign-in-redirect",
        redirect: "signin", // Redirige a la página de inicio de sesión
      },
      {
        path: "signin",
        name: "sign-in",
        component: () =>
          import(
            /* webpackChunkName: "signin" */ "../modules/auth/views/SignIn.vue"
          ),
      },
      {
        path: "signup",
        name: "sign-up",
        component: () =>
          import(
            /* webpackChunkName: "signup" */ "../modules/auth/views/SignUp.vue"
          ),
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () =>
          import(
            /* webpackChunkName: "signin" */ "../modules/auth/views/ForgotPassword.vue"
          ),
      },
      {
        path: "reset-password",
        name: "reset-password",
        component: () =>
          import(
            /* webpackChunkName: "signin" */ "../modules/auth/views/ResetPassword.vue"
          ),
        props: (route) => ({ token: route.query.token }), // Opcional si necesitas pasar el token como prop
      },
    ],
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "main layout" */ "../modules/shared/layout/MainLayout.vue"
      ),
    meta: { requiresAuth: true },
    children: [
      ...companyRoutes,
      ...userRoutes,
      ...propertyRoutes,
      {
        path: "dashboard",
        name: "resident-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "main layout" */ "../modules/resident/layout/ResidentLayout.vue"
          ),
        meta: { requiresAuth: true },
      },

      {
        path: "account",
        name: "account-settings",
        component: () =>
          import(
            /* webpackChunkName: "main layout" */ "../modules/shared/views/profile/AccountSettings.vue"
          ),
        meta: { requiresAuth: true, breadcrumb: "Account Settings" },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/signin", // Redirige a la página de inicio de sesión para rutas no definidas
  },
];

export default routes;
