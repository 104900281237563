import { RouteRecordRaw } from "vue-router";

const companyContactRoutes: RouteRecordRaw[] = [

  {
    path: "edit/:companyId/create-user",
    name: "create-company-contact",
    component: () =>
      import(
        /* webpackChunkName: "create company contact" */ "../modules/admin/submodules/company/views/CreateCompanyContact.vue"
      ),
    meta: {
      requiresAuth: true,
      parent: "company-list",
      breaddcums: [
        {
          name: "Companies",
          redirect: "company-list",
          disabled: false,
        },
        {
          name: "Edit Company",
          redirect: "edit-company",
          disabled: false,
          paramsKey: "companyId",
        },
        {
          name: "Add User",
          redirect: "create-company-contact",
          disabled: true,
        },
      ],
    },
  }, {
    path: "edit/:companyId/:contactId",
    name: "edit-company-contact",
    component: () =>
      import(
        /* webpackChunkName: "edit company contact" */ "../modules/admin/submodules/company/views/EditCompanyContact.vue"
      ),
    meta: {
      requiresAuth: true,
      parent: "company-list",
      breaddcums: [
        {
          name: "Companies",
          redirect: "company-list",
          disabled: false,
        },
        {
          name: "Edit Company",
          redirect: "edit-company",
          disabled: false,
          paramsKey: "companyId",
        },
        {
          name: "Edit User",
          redirect: "edit-company-contact",
          disabled: true,
          parentParamsKey: "companyId",
          paramsKey: "contactId",
        },
      ],
    },
  },

];

export default companyContactRoutes;
