import type { ThemeDefinition } from 'vuetify'

// Definir el tema personalizado
export const staticPrimaryColor = '#FF7F50'

export const themes: Record<string, ThemeDefinition> = {
  light: {
    dark: false,
    colors: {
      'primary': staticPrimaryColor, 
      'on-primary': '#FFFFFF',
      'primary-darken-1': '#9074E8', // Un poco más suave
      'secondary': '#A1A3A9', // Suavizado el gris
      'secondary-darken-1': '#8C8F94',
      'on-secondary': '#FFFFFF',
      'success': '#67D200', // Verde un poco más suave
      'success-darken-1': '#5EB000',
      'on-success': '#FFFFFF',
      'info': '#19AFFF', // Azul suavizado
      'info-darken-1': '#178FCC',
      'on-info': '#FFFFFF',
      'warning': '#FFB84D', // Amarillo menos intenso
      'warning-darken-1': '#E6A93D',
      'on-warning': '#FFFFFF',
      'error': '#FF6666', // Rojo menos agresivo
      'error-darken-1': '#E65555',
      'on-error': '#FFFFFF',
      'background': '#F5F5F5',
      'on-background': '#34343D',
      'surface': '#FFFFFF',
      'on-surface': '#34343D',
      'grey-50': '#FAFAFA',
      'grey-100': '#F5F5F5',
      'grey-200': '#EEEEEE',
      'grey-300': '#E0E0E0',
      'grey-400': '#BDBDBD',
      'grey-500': '#A3A3A3', // Un gris más suave
      'grey-600': '#7D7D7D',
      'grey-700': '#616161',
      'grey-800': '#424242',
      'grey-900': '#2A2A2A',
      'perfect-scrollbar-thumb': '#D1D1D1',
      'skin-bordered-background': '#FFFFFF',
      'skin-bordered-surface': '#FFFFFF',
      'expansion-panel-text-custom-bg': '#FAFAFA',
      'track-bg': '#E8E8F0',
      'chat-bg': '#F7F7FA',
      'disable': '#FFB38A' ,// Más suave
      'assigned': '#B4A4FF'
    },
  
    variables: {
      'code-color': '#D400FF',
      'overlay-scrim-background': '#34343D',
      'tooltip-background': '#1E1433',
      'overlay-scrim-opacity': 0.5,
      'hover-opacity': 0.04,
      'focus-opacity': 0.1,
      'selected-opacity': 0.08,
      'activated-opacity': 0.16,
      'pressed-opacity': 0.14,
      'dragged-opacity': 0.1,
      'disabled-opacity': 0.4,
      'border-color': '#34343D',
      'border-opacity': 0.12,
      'table-header-color': '#F6F7FB',
      'high-emphasis-opacity': 0.9,
      'medium-emphasis-opacity': 0.7,
      
      // Shadows
      'shadow-key-umbra-color': '#34343D',
      'shadow-xs-opacity': '0.16',
      'shadow-sm-opacity': '0.18',
      'shadow-md-opacity': '0.20',
      'shadow-lg-opacity': '0.22',
      'shadow-xl-opacity': '0.24',
    },
  },
  dark: {
    dark: true,
    colors: {
      'primary': staticPrimaryColor, 
      'on-primary': '#000000',
      'primary-darken-1': '#6544C3', // Menos saturado
      'secondary': '#C0C3CA', // Gris más claro
      'secondary-darken-1': '#A1A4A9',
      'on-secondary': '#000000',
      'success': '#67D285', // Verde menos brillante
      'success-darken-1': '#59B875',
      'on-success': '#000000',
      'info': '#58C3FF', // Azul menos saturado
      'info-darken-1': '#4BADEB',
      'on-info': '#000000',
      'warning': '#FFCD7A', // Amarillo menos intenso
      'warning-darken-1': '#E6B96A',
      'on-warning': '#000000',
      'error': '#FF7B7F', // Rojo suavizado
      'error-darken-1': '#E6666A',
      'on-error': '#000000',
      'background': '#212131', // Fondo principal más oscuro
      'on-background': '#DDDDDD',
      'surface': '#2D2D3F',
      'on-surface': '#DDDDDD',
      'grey-50': '#1F1F1F',
      'grey-100': '#292929',
      'grey-200': '#353535',
      'grey-300': '#434343',
      'grey-400': '#626262',
      'grey-500': '#787878',
      'grey-600': '#A0A0A0',
      'grey-700': '#C5C5C5',
      'grey-800': '#E0E0E0',
      'grey-900': '#F0F0F0',
      'perfect-scrollbar-thumb': '#3E3E3E',
      'skin-bordered-background': '#2D2D3F',
      'skin-bordered-surface': '#2D2D3F',
      'expansion-panel-text-custom-bg': '#2E2E2E',
      'track-bg': '#1E1E2D',
      'chat-bg': '#2D2D3F',
      'disable': '#C26C64',
      'assigned': '#9B86FF'
    },
    
    variables: {
      'code-color': '#D400FF',
      'overlay-scrim-background': '#34324B',
      'tooltip-background': '#E7E4FF',
      'overlay-scrim-opacity': 0.5,
      'hover-opacity': 0.04,
      'focus-opacity': 0.1,
      'selected-opacity': 0.08,
      'activated-opacity': 0.16,
      'pressed-opacity': 0.14,
      'disabled-opacity': 0.4,
      'dragged-opacity': 0.1,
      'border-color': '#E7E3FC',
      'border-opacity': 0.12,
      'table-header-color': '#453D59',
      'high-emphasis-opacity': 0.9,
      'medium-emphasis-opacity': 0.7,
      
      // Shadows
      'shadow-key-umbra-color': '#181423',
      'shadow-xs-opacity': '0.20',
      'shadow-sm-opacity': '0.22',
      'shadow-md-opacity': '0.24',
      'shadow-lg-opacity': '0.26',
      'shadow-xl-opacity': '0.28',
    },
  },
}

export default themes;