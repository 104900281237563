<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useTheme } from 'vuetify/lib/framework.mjs';
const theme = useTheme();
theme.global.name.value = localStorage.getItem('theme') || 'light';

</script>

<style>
@import './assets/styles/main.css';
</style>
