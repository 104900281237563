import { getUserRole } from "../services/jwtDecode";


const redirectTo = (token: string): string => {

  const role = getUserRole(token);
  const roleToRouteMap: { [key: number]: string } = {
    1: 'company-list',
    2: 'company-list',
    3: 'properties-list',
    4: 'resident-dashboard',
  };


  const routeName = roleToRouteMap[+role];
  return routeName;
}


export default redirectTo;