import axios from "axios";
import { jwtDecode } from "jwt-decode";


const BASE_URL = `${process.env.VUE_APP_API_URL}`;
interface JWTResponse {
  role: string;
  userName: string;
  id: string;
  exp: number;
}

// Esta función obtiene el rol del usuario desde el token JWT
export const getUserRole = (token: string): string => {
  try {
    const decoded = jwtDecode<JWTResponse>(token);
    if(decoded.role === '4') {
      localStorage.setItem('roleValue', decoded.role)
    }
    return decoded.role;
  } catch (e) {
    console.error("Error decoding token:", e);
    return '';
  }
};

export const getUserId = (token: string): string => {
  try {
    const decoded = jwtDecode<JWTResponse>(token);
    return decoded.id;
  } catch (e) {
    console.error("Error decoding token:", e);
    return '';
  }
};

// Esta función verifica si el token JWT es válido y no ha expirado
export const isTokenValid = async (token: string): Promise<boolean> => {
  if (!token) return false;
  try {
    const decoded = jwtDecode<JWTResponse>(token);
    const currentTime = Date.now() / 1000;

    // Si el token ha expirado, no hagas la llamada a la API
    if (decoded.exp <= currentTime) {
      return false;
    }

    await axios.get(`${BASE_URL}/auth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return true;
  } catch (e) {
    console.error("Error during token validation:", e);
    return false;
  }
};
