import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { isTokenValid } from "@/modules/shared/services/jwtDecode";
import redirectTo from "@/modules/shared/functions/redirectTo";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token") || "";


  const isValid = await isTokenValid(token);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isValid && to.name !== "sign-in") {

      return next({ name: "sign-in" });
    } else if (isValid && to.name === "sign-in") {
      const routeName = redirectTo(token);
      return next({ name: routeName });
    } else {
      return next();
    }
  } else if (isValid && to.name === "sign-in") {
    const routeName = redirectTo(token);
    return next({ name: routeName });
  } else {
    return next();
  }
});

export default router;
